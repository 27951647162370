import { stateContext } from 'Contexts/StateContext';
import { useContext } from 'react';
import { ReactFitty } from 'react-fitty';

import './Timedate.scss';

const queryParams: { [Value: string]: any } = new URLSearchParams(window.location.search); // prettier-ignore
const dateTimeSplit = parseInt(queryParams.get('dateTimeSplit')) || 0;

const Timedate = () => {
  const [state] = useContext(stateContext);
  return (
    <div className="time-container">
      {dateTimeSplit ? (
        <div className="time">
          {state.date}
          <br />
          {state.time}
        </div>
      ) : (
        <ReactFitty className="time">{state.datetime}</ReactFitty>
      )}
    </div>
  );
};

export default Timedate;
